@import "breakpoints";
@import "fonts";

$font-content: OpenSansRegular, sans-serif;
$font-monospace: CutiveMono, sans-serif;

.title-large {
  line-height: 1;
}

.title-small, .title-medium, .title-large {
  font-family: $font-content;
  font-weight: bold;
}

.content-small, .content-medium, .content-large {
  font-family: $font-content;
}

.monospace {
  font-family: $font-monospace;
}

@media (max-width: $breakpoint-small) {
  .title-small {
    font-size: 14px;
    line-height: 1.25;
  }

  .title-medium {
    font-size: 18px;
    line-height: 1.25;
  }

  .title-large {
    font-size: 28px;
  }

  .content-small {
    font-size: 12px;
  }

  .content-medium {
    font-size: 15px;
    line-height: 1.45;
  }

  .content-large {
    font-size: 17px;
  }

  .monospace {
    font-size: 14px;
  }
}

@media (min-width: $breakpoint-small) {
  .title-small {
    font-size: 14px;
  }

  .title-medium {
    font-size: 18px;
  }

  .title-large {
    font-size: 26px;
  }

  .content-small {
    font-size: 12px;
  }

  .content-medium {
    font-size: 16px;
  }

  .content-large {
    font-size: 18px;
  }

  .monospace {
    font-size: 16px;
  }
}

@media (min-width: $breakpoint-medium) {
  .title-small {
    font-size: 16px;
    line-height: 20px;
  }

  .title-medium {
    font-size: 18px;
  }

  .title-large {
    font-size: 30px;
  }

  .content-small {
    font-size: 15px;
  }

  .content-medium {
    font-size: 16px;
    line-height: 1.5;
  }

  .content-large {
    font-size: 22px;
  }

  .monospace {
    font-size: 16px;
  }
}

@media (min-width: $breakpoint-large) {
  .title-small {
    font-size: 18px;
    line-height: 22px;
  }

  .title-medium {
    font-size: 30px;
  }

  .title-large {
    font-size: 35px;
  }

  .content-small {
    font-size: 15px;
  }

  .content-medium {
    font-size: 19px;
    line-height: 1.25;
  }

  .content-large {
    font-size: 24px;
  }

  .monospace {
    font-size: 16px;
  }
}

@media (min-width: $breakpoint-extra-large) {
  .title-small {
    font-size: 19px;
    line-height: 24px;
  }

  .title-medium {
    font-size: 26px;
    line-height: 1.2;
  }

  .title-large {
    font-size: 35px;
  }

  .content-small {
    font-size: 15px;
  }

  .content-medium {
    font-size: 18px;
    line-height: 1.4;
  }

  .content-large {
    font-size: 24px;
  }

  .monospace {
    font-size: 16px;
  }
}
