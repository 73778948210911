/*************
  Font Faces
**************/

@font-face {
  font-family: CutiveMono;
  src: url('../assets/fonts/CutiveMono-Regular.ttf');
}

@font-face {
  font-family: OpenSansRegular;
  src: url('../assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: OpenSansRegular;
  src: url('../assets/fonts/OpenSans-Bold.ttf');
  font-weight: bold;
}
