@import "breakpoints";

@media (max-width: $breakpoint-small) {
  .skew-up-top-right {
    margin: 0 -10px -20px;
    padding: 20px 0;
    transform: rotate(-3deg);
  }

  .skew-down-bottom-left {
    margin: -20px -10px 50px;
    padding: 20px 0;
    transform: rotate(-3deg);
  }
}

@media (min-width: $breakpoint-small) {
  .skew-up-top-right {
    margin: 0 -10px -20px;
    padding: 20px 0;
    transform: rotate(-3deg);
  }

  .skew-down-bottom-left {
    margin: -20px -10px 50px;
    padding: 20px 0;
    transform: rotate(-3deg);
  }
}

@media (min-width: $breakpoint-medium) {
  .skew-up-top-right {
    margin: 0 -10px -30px;
    padding: 30px 0;
    transform: rotate(-3deg);
  }

  .skew-down-bottom-left {
    margin: -30px -10px 50px;
    padding: 30px 0;
    transform: rotate(-3deg);
  }
}

@media (min-width: $breakpoint-large) {
  .skew-up-top-right {
    margin: 30px -10px -30px;
    padding: 40px 0;
    transform: rotate(-2.5deg);
  }

  .skew-down-bottom-left {
    margin: -30px -10px 50px;
    padding: 30px 0;
    transform: rotate(-2.5deg);
  }
}

@media (min-width: $breakpoint-extra-large) {
  .skew-up-top-right {
    margin: 50px -10px -60px;
    padding: 60px 0;
    transform: rotate(-1.5deg);
  }

  .skew-down-bottom-left {
    margin: -50px -10px 60px;
    padding: 60px 0;
    transform: rotate(-1.5deg);
  }
}