@import "borders";
@import "breakpoints";
@import "cards";
@import "charts";
@import "colors";
@import "fonts";
@import "links";
@import "popups";
@import "positioning";
@import "forms";
@import "images";
@import "text";
@import "transforms";

body, #root {
  min-height: 100vh;
  position: relative;
}

body {
  margin: 0;
}

@media (max-width: $breakpoint-small) {
  .main {
    padding-bottom: 115px;
  }
}

@media (min-width: $breakpoint-small) {
  .main {
    padding-bottom: 130px;
  }
}

@media (min-width: $breakpoint-medium) {
  .main {
    padding-bottom: 90px;
  }
}

@media (min-width: $breakpoint-large) {
  .main {
    padding-bottom: 70px;
  }
}

@media (min-width: $breakpoint-extra-large) {
  .main {
    padding-bottom: 70px;
  }
}
