@import "breakpoints";
@import "colors";
@import "fonts";
@import "text";

$field-height-small: 40px;

.field-input {
  background: $white;
  border-color: $black;
  border-width: 2px;
  border-radius: 5px;
  font-family: $font-content;
  font-size: 16px;
  padding: 8px 14px;
  height: $field-height-small;
  width: 100%;

  &::placeholder {
    color: $black;
  }

  &.area {
    min-height: 200px;
    margin: 0;
  }
}

.button-submit {
  border-color: $black;
  border-width: 2px;
  border-radius: 5px;
  cursor: pointer;
  font-family: $font-content;
  font-weight: bold;
  font-size: 16px;
  padding: 0 55px;
  height: $field-height-small;
  color: $white;
  text-transform: uppercase;
}

.field-input-small {
  background: $white;
  border-color: $black;
  border-width: 1px;
  border-radius: 3px 0 0 3px;
  font-family: $font-content;
  font-size: 16px;
  padding: 0 10px;
  height: 38px;
  width: 100%;

  &::placeholder {
    color: $black;
  }
}

.button-submit-small {
  border: 1px solid $black;
  border-left-style: none;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  font-family: $font-content;
  font-weight: bold;
  font-size: 20px;
  height: 38px;
  color: $white;
  width: 100%;
  text-transform: uppercase;
}

@media (min-width: $breakpoint-medium) {
  .field-input {
    border-width: 3px;
    border-radius: 5px;
    font-size: 20px;
    padding: 15px;
    height: 60px;
    width: 100%;

    &.area {
      min-height: 200px;
      margin: 0;
    }
  }

  .button-submit {
    border-width: 3px;
    border-radius: 5px;
    font-size: 20px;
    line-height: 45px;
    padding: 0 75px;
    margin: 20px 0 30px;
    height: 50px;
  }

  .field-input-small {
    border-width: 1px;
    border-radius: 3px 0 0 3px;
    // TODO: font size shouldn't be here. Should be a font class
    font-size: 12px;
    padding: 0 10px;
    height: 30px;
  }

  .button-submit-small {
    border: 1px solid $black;
    border-left-style: none;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    font-size: 16px;
    height: 30px;
    width: 100%;
  }
}

@media (min-width: $breakpoint-large) {
  .field-input {
    border-width: 3px;
    border-radius: 5px;
    font-size: 20px;
    padding: 20px;
    height: 55px;

    &.area {
      min-height: 275px;
    }
  }

  .button-submit {
    border-width: 3px;
    border-radius: 5px;
    font-size: 20px;
    padding: 0 75px;
    margin: 25px 0 40px;
    height: 50px;
  }

  .field-input-small {
    border-width: 1px;
    border-radius: 3px 0 0 3px;
    font-size: 16px;
    padding: 0 10px;
    height: 38px;

  }

  .button-submit-small {
    border: 1px solid $black;
    border-left-style: none;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    font-size: 20px;
    height: 38px;
    width: 100%;
  }
}