@import "colors";

.border-top-black {
  border-top: 2px solid $black;
}

.border-bottom-black {
  border-bottom: 1px solid $black;
}

.scroll-indicator {
  height: 2px;
}