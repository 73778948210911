@import "breakpoints";

.social {
  height: 25px;
  margin: 0 8px;
  width: auto;
}

.post-image {
  margin: 25px auto;
  max-width: 60%;
}

// TODO: Maybe I should try and generalise these classes. Like absolute fit or something.
.portfolio-item {
  height: auto;
  width: 100%;
}

.logo {
  height: 60px;
  width: auto;
  margin: 25px 70px;
}

.post-banner {
  width: 100%;
  min-height: auto;
}

.tag {
  height: 12.5px;
  width: auto;
}

.inverted-gelato {
  img {
    transform: rotate(180deg);
    height: 150px;
  }
}

// TODO: rename this
.tag-2 {
  height: 18px;
  width: auto;
}

// TODO: rename this
.tag-3 {
  height: 25px;
  width: auto;
}

.myself {
  height: 200px;
  margin-bottom: 20px;
  width: auto;
}

.icon-scroll {
  img {
    cursor: pointer;
    height: 50px;
    width: auto;
  }
}

@media (max-width: $breakpoint-small) {
  .logo {
    height: 45px;
  }
}

@media (min-width: $breakpoint-medium) {
  .social {
    height: 30px;
    margin: 0 10px;
  }

  .logo {
    height: 45px;
    margin: 25px 0;
  }

  .myself {
    height: 250px;
    margin-bottom: 20px;
  }
}

@media (min-width: $breakpoint-large) {
  .social {
    height: 35px;
    margin: 0 0 0 25px;
  }

  .logo {
    height: 60px;
    margin: 25px 0;
  }

  .myself {
    height: 250px;
    margin-bottom: 20px;
  }
}
