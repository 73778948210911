$black: #212121;
$white: #FFF;

$blue-dark: #016EFF;
$cyan-dark: #00FFBF;
$red-dark: #FF0A43;
$yellow-dark: #FFDC00;

$blue-light: #FAFCFF;
$cyan-light: #FAFFFE;
$red-light: #FFFAFB;
$yellow-light: #FEFFF8;

/***********
  Classes
************/

.color-white {
  color: $white;
}

.color-black {
  color: $black;
}

.color-blue-dark {
  color: $blue-dark;
}

.color-cyan-dark {
  color: $cyan-dark;
}

.color-red-dark {
  color: $red-dark;
}

.color-yellow-dark {
  color: $yellow-dark;
}

.bg-blue-dark {
  background: $blue-dark;
}

.bg-cyan-dark {
  background: $cyan-dark;
}

.bg-red-dark {
  background: $red-dark;
}

.bg-yellow-dark {
  background: $yellow-dark;
}

.bg-blue-light {
  background: $blue-light;
}

.bg-cyan-light {
  background: $cyan-light;
}

.bg-red-light {
  background: $red-light;
}

.bg-yellow-light {
  background: $yellow-light;
}
