@import "colors";
@import "text";

.card {
  background: $white;
  border: none;
  border-radius: 2px;
  box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.04);
  color: $black;
  font-family: $font-content;
  padding: 14px;
  transition-duration: .35s;

  * {
    text-decoration: none !important;
  }

  a {
    color: $black;
  }

  &:hover {
    box-shadow: 1px 1px 6px 4px rgba(0, 0, 0, 0.08);
    transition-duration: .35s;
  }
}
