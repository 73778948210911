@import "breakpoints";
@import "colors";

.popup-overlay {
  background: #FFFAFBCC;
  position: fixed;
  width: 100%;
  z-index: 10000;

  .popup {
    background: $white;
    box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    opacity: 1;
    position: relative;
    width: 85%;

    .popup-close {
      background: $red-dark url("../assets/images/close.png") no-repeat 12px 12px;
      background-size: 16px 16px;
      border-radius: 50%;
      cursor: pointer;
      height: 40px;
      opacity: 1;
      position: absolute;
      right: -12px;
      top: -12px;
      width: 40px;
    }

    img {
      height: 55px;
      width: auto;
    }

    @media (min-width: $breakpoint-medium) {
      width: 55%;

      img {
        height: 75px;
        width: auto;
      }
    }
  }
}